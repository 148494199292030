<template>
  <v-app id="initial-loading" dark>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-row>
    </v-container>
  </v-app>
</template>

<style scoped></style>

<script>
import moment from "moment";
import { mapMutations } from "vuex";

export default {
  props: {
    returnTo: Object,
  },
  data: function() {
    return {
      //
    };
  },
  methods: {
    ...mapMutations({
      setLoaded: "app/SET_LOADED",
    }),
  },
  mounted() {
    var promises = [];
    promises.push(this.$store.dispatch("users/load"));
    promises.push(this.$store.dispatch("users/platforms"));
    promises.push(this.$store.dispatch("resellers/load"));
    promises.push(this.$store.dispatch("licences/load"));
    promises.push(
      this.$store.dispatch("orders/load", {
        startDate: moment
          .utc()
          .add(-30, "days")
          .toISOString(),
        endDate: moment.utc().toISOString(),
      })
    );

    Promise.allSettled(promises).then(() => {
      this.setLoaded();
      if (this.returnTo) {
        this.$router.push(this.returnTo);
      } else {
        this.$router.push("/dashboard");
      }
    });
  },
};
</script>
